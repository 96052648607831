import { Modal, StyledButton } from './Modal';
import React from 'react';
import styled from 'styled-components';
import { PlanningType } from '../Planning/PlanningType';

interface SaveModal {
  open: boolean;
  closeModal: () => void;
  selectedPlanning: PlanningType | null;
  leaveEditMode: (selectedPlanning: PlanningType) => Promise<void>;
  setChangeIsMade: (changeIsMade: boolean) => void;
  savePlanning: (planningId: string) => Promise<void>;
}

export function SaveModal(props: SaveModal) {
  return (
    <Modal
      open={props.open}
      closeModal={props.closeModal}
      headerText={'Save Changes?'}
    >
      <TextDiv>Do you want to save your changes?</TextDiv>
      <ButtonDiv>
        <CancelButton onClick={props.closeModal}>Cancel</CancelButton>
        <DontSaveButton
          onClick={async () => {
            if (props.selectedPlanning) {
              props.closeModal();
              await props.leaveEditMode(props.selectedPlanning);
              props.setChangeIsMade(false);
            }
          }}
        >
          Don't save
        </DontSaveButton>
        <SaveButton
          onClick={async () => {
            if (props.selectedPlanning) {
              props.closeModal();
              await props.savePlanning(props.selectedPlanning.id);
              await props.leaveEditMode(props.selectedPlanning);
              props.setChangeIsMade(false);
            }
          }}
        >
          Save
        </SaveButton>
      </ButtonDiv>
    </Modal>
  );
}

const TextDiv = styled.div`
  width: 100%;
  height: 40px;
  text-align: left;
  border: 0;
  outline: none;
  font: inherit;
  background: inherit;
  color: black;
  margin-bottom: 20px;
`;

const ButtonDiv = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: right;
`;

const CancelButton = styled(StyledButton)`
  width: 90px;
  height: 40px;
  background-color: white;
  box-shadow: inset 0 0 0 1.5px var(--twd_web_grey);
  color: var(--twd_web_grey);
  margin-right: 20px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 8px;
  &:hover {
    transform: none;
    background-color: var(--twd_grey);
    box-shadow: inset 0 0 0 1.5px var(--twd_grey);
  }
`;

const DontSaveButton = styled(StyledButton)`
  width: 90px;
  height: 40px;
  background-color: var(--twd_signal);
  box-shadow: inset 0 0 0 1.5px var(--twd_signal);
  color: white;
  margin-right: 20px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 8px;
  &:hover {
    transform: none;
  }
`;

const SaveButton = styled(StyledButton)`
  width: 90px;
  height: 40px;
  background-color: var(--twd_aqua);
  box-shadow: inset 0 0 0 1.5px var(--twd_aqua);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    transform: none;
  }
`;
