import { styled } from 'styled-components';
import { ProjectDataType } from '../Report/LeftColumn/InputType';
import { ProjectNumberInput } from '../ProjectNumber';
import React from 'react';
import { ToggleButtons } from '../ToggleButtons/ToggleButton';

interface ChangeAppBarProps {
  projects: ProjectDataType[];
  setSelectedProject: (selectedProject: ProjectDataType | null) => void;
  isPlanningSelected: boolean;
  setSelectedTab: (p: boolean) => void;
  inEditMode: boolean;
}

export function ChangeAppBar(props: ChangeAppBarProps) {
  return (
    <ChangeAppBarDiv>
      <ProjectNumberInput
        setSelectedProject={props.setSelectedProject}
        projects={props.projects}
        disabled={props.inEditMode}
      />
      <ToggleButtons
        firstButtonText={'Planning'}
        secondButtonText={'Reporting'}
        value={props.isPlanningSelected}
        onChange={props.setSelectedTab}
      />
    </ChangeAppBarDiv>
  );
}

const ChangeAppBarDiv = styled.div`
  width: calc(100% - 40px);
  height: 80px;
  margin-bottom: 10px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 0 20px;
  gap: 8px;
  z-index: 100;
  border-radius: 8px;
  box-shadow: inset 0 0 0 1px var(--twd_web_grey);
`;
