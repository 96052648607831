import {
  ProjectDataType,
  ProjectNumberProps,
} from './Report/LeftColumn/InputType';
import { AutoComplete } from './AutoComplete/AutoComplete';
import { styled } from 'styled-components';

const ProjectNumberDiv = styled.div<{
  isInputFieldSelected?: boolean;
}>`
  width: calc(50% - 25px);
  height: 40px;
  background: white;
`;

export function ProjectNumberInput(props: ProjectNumberProps) {
  return (
    <ProjectNumberDiv>
      <AutoComplete
        options={props.projects}
        optionLabels={(project: ProjectDataType) =>
          project.ProjectNumber + ' ' + project.ProjectName
        }
        onChange={(value) => {
          props.setSelectedProject(value);
        }}
        placeholder={'Select Project'}
        disabled={props.disabled}
      />
    </ProjectNumberDiv>
  );
}
