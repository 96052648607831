import React, { MouseEvent, ReactElement, useState } from 'react';
import { AutoComplete } from '../../../AutoComplete/AutoComplete';
import { PlanningType } from '../../PlanningType';
import { PlanningSelectProps } from './PlanningSelect.types';
import { styled } from 'styled-components';
import {
  deleteFromAPI,
  postFromAPI,
  putFromAPI,
} from '../../../../services/apiCalls';
import { PlanningButtonOperations } from './PlanningButtonOperations/PlanningButtonOperations';
import { AddPlanningSection } from './AddPlanningSection/AddPlanningSection';
import { CreationModal } from '../../../Modals/CreationModal';

const PlanningSelectDiv = styled.div`
  width: 300px;
  height: 40px;
  background: white;
`;

export function PlanningSelect(props: PlanningSelectProps): ReactElement {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [planningName, setPlanningName] = useState<string>('');
  const [planningForModal, setPlanningForModal] = useState<PlanningType | null>(
    null
  );

  const updateEditModalData = (planning: PlanningType) => {
    setPlanningName(planning.name);
    setPlanningForModal(planning);
    setOpenModal(true);
  };

  const changePlanningName = (value: string) => {
    setPlanningName(value);
  };

  const closeModal = () => {
    setOpenModal(false);
    setPlanningName('');
    setPlanningForModal(null);
  };

  const createPlanning = async (name: string): Promise<PlanningType | null> => {
    props.setIsLoading(true);
    const response: Response | null = await postFromAPI(
      '/budget-planning/create',
      {
        name: name,
        project_id: props.projectId,
      }
    );
    const reponseJson = await response.json();
    if (response?.ok) {
      return reponseJson.data;
    }
    return null;
  };

  const copyPlanning = async (planning: PlanningType) => {
    const newPlanning: PlanningType | null = await createPlanning(
      planning.name + ' (COPY)'
    );
    let copyObject = null;
    if (newPlanning) {
      console.log(planning);
      await props.saveCopiedPlanning(planning.id, newPlanning.id);
      props.setAllPlannings([...props.allPlannings, newPlanning]);
      copyObject = {
        planning: newPlanning,
        plannings: [...props.allPlannings, newPlanning],
      };
    }

    return copyObject;
  };

  const deletePlanning = async (planning: PlanningType) => {
    props.setIsLoading(true);
    const planningId = planning.id;
    const response = await deleteFromAPI(
      `/budget-planning/delete/${Number(planningId)}`
    );
    if (response.ok) {
      const filteredPlannings = props.allPlannings.filter(
        (planning) => planning.id !== planningId
      );
      props.setAllPlannings(filteredPlannings);
    }
    props.setIsLoading(false);
  };

  const editPlanningName = async (planning: PlanningType, newName: string) => {
    props.setIsLoading(true);
    const planningId = planning.id;
    const projectId = planning.project_id;
    const newPlanning = {
      id: Number(planningId),
      name: newName,
      project_id: projectId,
      editor: planning.editor,
    };

    const response = await putFromAPI('/budget-planning/update', newPlanning);
    if (response.ok) {
      const allPlannings = props.allPlannings.map((p: PlanningType) => {
        if (p.id === planningId) {
          return { ...newPlanning, id: planningId };
        } else {
          return p;
        }
      });
      props.setAllPlannings(allPlannings);
    }
    props.setIsLoading(false);
  };

  const changePlanningNameButtonClicked = async () => {
    if (planningForModal) {
      await editPlanningName(planningForModal, planningName);
    }
    closeModal();
  };

  return (
    <PlanningSelectDiv>
      <CreationModal
        open={openModal}
        closeModal={closeModal}
        headerText={'Change plan name'}
        value={planningName}
        setValue={changePlanningName}
        createPlanning={changePlanningNameButtonClicked}
        blueButtonText={'Change'}
      />
      <AutoComplete
        options={props.allPlannings}
        optionLabels={(planning: PlanningType) => planning.name}
        onChange={(value: PlanningType | null) => {
          props.setPlanningId(value);
        }}
        placeholder={'Select Planning'}
        autoSelectIndex={0} //TODO: Add favourite/default planning
        disabled={props.projectId === '' || props.inEditMode}
        extraModalSection={(modalProps) => (
          <AddPlanningSection
            {...modalProps}
            createPlanning={createPlanning}
            setIsLoading={props.setIsLoading}
            allPlannings={props.allPlannings}
            setAllPlannings={props.setAllPlannings}
          />
        )}
        extraButtonContent={(buttonProps) => (
          <PlanningButtonOperations
            {...buttonProps}
            copyPlanning={copyPlanning}
            deletePlanning={deletePlanning}
            updateEditModalData={updateEditModalData}
          />
        )}
      />
    </PlanningSelectDiv>
  );
}
