import React, {
  Dispatch,
  memo,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { usePlanningUpdates } from '../Planning.hooks';
import { getBaseColumns } from './Columns';
import {
  DataGridPremium,
  GRID_AGGREGATION_FUNCTIONS,
  GRID_ROW_GROUPING_SINGLE_GROUPING_FIELD,
  GridAggregationFunction,
  GridCellParams,
  gridClasses,
  GridColDef,
  GridTreeNode,
  GridValidRowModel,
  useKeepGroupedColumnsHidden,
} from '@mui/x-data-grid-premium';
import { ColumnValue } from '../Functions/GridFunctions';
import { PlanningSpent } from '../../../Settings';
import {
  PhaseData,
  PlanningData,
  PlanningType,
  RowEntry,
} from '../PlanningType';
import { EditToolbar } from '../Toolbar/Toolbar';
import { CustomColumnMenu, CustomColumnMenuIcon } from './CustomColumnMenu';
import { ProjectType } from '../../../App.types.';

interface DataGridProps {
  projectData: ProjectType;
  planningData: PlanningData;
  setUpdatedRows: Dispatch<SetStateAction<RowEntry[]>>;
  phaseData: PhaseData;
  selectedPlanning: PlanningType | null;
  setSelectedPlanning: (selectedPlanning: PlanningType | null) => void;
  inEditMode: boolean;
  setIsloading: (p: boolean) => void;
  allPlannings: PlanningType[];
  setAllPlannings: (p: PlanningType[]) => void;
  alreadyInUse: string;
  setChangeIsMade: Dispatch<SetStateAction<boolean>>;
}

// Columns that only require clicking once to open the cell.
const singleClickColumns = ['color', 'rate'];

export const PlanningTable = memo(function DataGrid(props: DataGridProps) {
  const {
    apiRef,
    addRow,
    deleteRow,
    copyRow,
    addColumn,
    changePlanningSettings,
  } = usePlanningUpdates(
    props.projectData,
    props.planningData,
    props.setUpdatedRows,
    props.setChangeIsMade
  );

  const [baseColumns, setBaseColumns] = useState<GridColDef<RowEntry>[]>([]);
  useEffect(() => {
    setBaseColumns(
      getBaseColumns(
        addRow,
        deleteRow,
        copyRow,
        addColumn,
        props.projectData,
        props.phaseData,
        props.inEditMode
      )
    );
  }, [props.selectedPlanning, props.phaseData, props.inEditMode]);

  const valueWeekAggregation: GridAggregationFunction<ColumnValue, string> = {
    label: '',
    apply: (params) => {
      if (!params.values) {
        return '';
      }
      const SpentAddedPerPhase: string[] = [];
      let valueSum: number = params.values.reduce((sum: number, value) => {
        let newValue = (value?.value || 0) * (value?.rate || 0);
        if (
          value?.spent &&
          value?.spent > 0 &&
          !SpentAddedPerPhase.includes(value?.partId)
        ) {
          newValue += value?.spent;
          SpentAddedPerPhase.push(value?.partId);
        }
        return (sum || 0) + (newValue || 0);
      }, 0);
      // Call setAggregatedPlanned to store planned per week or per phase in Graph props
      // setAggregatedPlanned(params.field, String(params.groupId), valueSum);
      if (valueSum === 0) return '0';
      return valueSum?.toLocaleString('nl-NL', {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      });
    },
    valueFormatter: (value) => props.projectData.currency + ' ' + value,
    columnTypes: ['number'],
  };

  const handleCellClick = React.useCallback(
    (params: GridCellParams, event: React.MouseEvent) => {
      if (!params.isEditable) {
        return;
      }

      // Ignore portal (not completely sure, this came from https://mui.com/x/react-data-grid/recipes-editing/#single-click-editing)
      if (
        (event.target as any).nodeType === 1 &&
        !event.currentTarget.contains(event.target as Element)
      ) {
        return;
      }
      if (
        singleClickColumns.includes(params.field) &&
        apiRef.current.getCellMode(params.id, params.field) === 'view'
      ) {
        apiRef.current.startCellEditMode({
          id: params.id,
          field: params.field,
        });
      }
    },
    []
  );

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      rowGrouping: {
        model: ['part'],
      },
      pinnedColumns: {
        left: [
          GRID_ROW_GROUPING_SINGLE_GROUPING_FIELD,
          'color',
          'task',
          'actions',
          'rate',
          'employee',
          `${PlanningSpent}`,
        ],
        right: ['new-week', 'planned', 'total'],
      },
    },
  });

  return (
    <>
      <EditToolbar
        apiRef={apiRef}
        projectData={props.projectData}
        allPlannings={props.allPlannings}
        setAllPlannings={props.setAllPlannings}
        planningId={props.selectedPlanning}
        setPlanningId={props.setSelectedPlanning}
        setIsloading={props.setIsloading}
        inEditMode={props.inEditMode}
        alreadyInUse={props.alreadyInUse}
        changePlanningSettings={changePlanningSettings}
      />
      <DataGridPremium
        sx={{
          border: 0,
          '& .MuiDataGrid-main': {
            border: 'solid 1px var(--twd_border_grey)',
          },
          '& .MuiDataGrid-cell': { padding: '0 5px' },
          '& .MuiInputBase-input': { padding: '0 5px' },
          '& .MuiDataGrid-columnHeader': {
            color: 'white',
            background: 'var(--twd_aqua)',
          },
          '& .MuiDataGrid-columnHeaders .MuiDataGrid-filler': {
            color: 'white',
            background: 'var(--twd_aqua)',
          },
          '& .MuiDataGrid-columnHeaders .MuiDataGrid-columnSeparator': {
            color: 'white',
          },
          '& .MuiDataGrid-scrollbar': {
            zIndex: 1,
          },
          '& .MuiDataGrid-columnHeaders .MuiDataGrid-scrollbarFiller': {
            background: 'var(--twd_aqua)',
          },
          ...CellColors,
        }}
        apiRef={apiRef}
        initialState={initialState}
        rows={props.planningData.rows}
        rowHeight={30}
        columnHeaderHeight={30}
        getRowClassName={(params) =>
          `super-app-theme--${params.row.spent && params.row.spent >= 0 ? `${PlanningSpent}` : ''}`
        }
        columns={[...baseColumns, ...props.planningData.weekColumns]}
        disableColumnFilter
        cellSelection
        rowSelection={false}
        hideFooter={true}
        onCellClick={handleCellClick}
        processRowUpdate={(newRow, oldRow) => {
          props.setUpdatedRows((oldUpdatedRows: RowEntry[]) =>
            oldUpdatedRows.map((oldUpdatedRow: RowEntry) => {
              if (oldUpdatedRow.id === newRow.id) {
                return newRow; // Replace the row with the updated row
              }
              return oldUpdatedRow; // Return the original row if it doesn't match
            })
          );
          props.setChangeIsMade(true);
          return newRow;
        }}
        aggregationFunctions={{
          ...GRID_AGGREGATION_FUNCTIONS,
          valueWeek: valueWeekAggregation,
        }}
        groupingColDef={{
          hideDescendantCount: true, // Hide the number next to the group
        }}
        getCellClassName={(
          params: GridCellParams<
            any,
            GridValidRowModel,
            GridValidRowModel,
            GridTreeNode
          >
        ) => {
          if (
            [
              'color',
              'task',
              'actions',
              'rate',
              'employee',
              `${PlanningSpent}`,
            ].includes(params.field) &&
            params.row.color !== 'no color'
          ) {
            return params.row.color;
          }
          return '';
        }}
        slots={{
          columnMenu: CustomColumnMenu,
          columnMenuIcon: CustomColumnMenuIcon,
        }}
      />
    </>
  );
});

const CellColors = {
  [`.${gridClasses.cell}.green`]: {
    backgroundColor: 'lightgreen',
  },
  [`.${gridClasses.cell}.red`]: {
    backgroundColor: 'var(--twd_signal_1)',
  },
  [`.${gridClasses.cell}.blue`]: {
    backgroundColor: 'var(--twd_aqua_pale_2)',
  },
};
