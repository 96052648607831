import React, { useEffect, useMemo, useState } from 'react';
import {
  DataGridPremium,
  GRID_AGGREGATION_FUNCTIONS,
  GridAggregationFunction,
  GridColDef,
} from '@mui/x-data-grid-premium';
import {
  BudgetTableProps,
  initialTableEntry,
  TableRowEntry,
} from './BudgetTableType';
import { RowEntry } from '../PlanningType';
import { isBefore, startOfWeek } from 'date-fns';
import { styled } from 'styled-components';
import { formatCurrencyFields } from '../Functions/GridFunctions';

export function BudgetTable(props: BudgetTableProps) {
  const [initialTableEntries, setInitialTableEntries] =
    useState<initialTableEntry>({});
  const [tableRows, setTableRows] = useState<TableRowEntry[]>([]);

  useEffect(() => {
    const phaseRows = Object.entries(props.projectData.phases).reduce(
      (acc, [phaseCode, phaseName]) => {
        const spent = props.phaseData[phaseCode]?.actualCosting || 0;
        const budget = props.phaseData[phaseCode]?.costEstimate || 0;

        acc[phaseCode] = {
          id: phaseCode,
          phase: phaseName,
          budget: budget,
          spent: spent,
          planned: 0,
          total: spent,
        };
        return acc;
      },
      {} as Record<string, TableRowEntry>
    );
    setInitialTableEntries(phaseRows);
  }, [props.phaseData]);

  useEffect(() => {
    const rows = props.updatedRows;
    const initialEntries = initialTableEntries;
    const columnPhaseEntries: { [partId: string]: number } = {};
    const currentWeekDate = startOfWeek(new Date(), { weekStartsOn: 1 });
    if (rows.length > 0) {
      rows.forEach((row: RowEntry) => {
        const {
          id,
          partId,
          part,
          color,
          employee,
          task,
          rate,
          ...columnEntries
        } = row;
        const rowRate = rate ? props.projectData.rates[rate] : 1;
        if (!columnPhaseEntries[partId]) {
          columnPhaseEntries[partId] = 0;
        }

        columnPhaseEntries[partId] += Object.entries(columnEntries).reduce(
          (sum, [dateString, columnEntry]) => {
            const [day, month, year] = dateString.split('-');
            let fieldDate = new Date(`${year}-${month}-${day}`);
            fieldDate = startOfWeek(fieldDate, { weekStartsOn: 1 });
            if (isBefore(fieldDate, currentWeekDate)) {
              return sum;
            }
            sum += columnEntry * rowRate;
            return sum;
          },
          0
        ) as number;
        if (initialEntries[partId]) {
          initialEntries[partId].planned = columnPhaseEntries[partId];
          initialEntries[partId].total =
            initialEntries[partId].spent + columnPhaseEntries[partId];
        }
      });
    }
    const sortedRows = Object.values(initialEntries).sort((a, b) =>
      a.phase.localeCompare(b.phase)
    );
    setTableRows(sortedRows);
  }, [initialTableEntries, props.updatedRows]);

  const columns: GridColDef<TableRowEntry>[] = useMemo(
    () => [
      {
        field: 'phase',
        headerName: 'Phase',
        type: 'string',
        flex: 2,
        editable: false,
        sortable: true,
      },
      {
        field: 'budget',
        headerName: 'Budget',
        type: 'number',
        flex: 2,
        editable: false,
        sortable: true,
        valueFormatter: (value: number) =>
          value
            ? formatCurrencyFields(props.projectData.currency, value)
            : value,
      },
      {
        field: 'spent',
        headerName: 'Spent',
        type: 'number',
        flex: 2,
        editable: false,
        sortable: true,
        valueFormatter: (value: number) =>
          value
            ? formatCurrencyFields(props.projectData.currency, value)
            : value,
      },
      {
        field: 'spent_percentage',
        headerName: '%',
        type: 'number',
        flex: 1,
        editable: false,
        sortable: false,
        disableReorder: true,
        filterable: false,
        disableColumnMenu: true,
        valueGetter: (_, row, _1, _2) => {
          const spent = row.spent;
          const budget = row.budget;
          const spent_percentage =
            spent === 0 || budget === 0 ? 0 : (spent / budget) * 100;
          return Math.round(spent_percentage);
        },
        valueFormatter: (value: number) =>
          !value || value === 0 ? '' : value + '%',
      },
      {
        field: 'planned',
        headerName: 'Planned',
        type: 'number',
        flex: 2,
        editable: false,
        sortable: true,
        valueFormatter: (value: number) =>
          value
            ? formatCurrencyFields(props.projectData.currency, value)
            : value,
      },
      {
        field: 'total',
        headerName: 'Total',
        type: 'number',
        flex: 2,
        editable: false,
        sortable: true,
        valueFormatter: (value: number) =>
          value
            ? formatCurrencyFields(props.projectData.currency, value)
            : value,
      },
      {
        field: 'total_percentage',
        headerName: '%',
        type: 'number',
        flex: 1,
        editable: false,
        sortable: false,
        disableReorder: true,
        filterable: false,
        disableColumnMenu: true,
        valueGetter: (_, row, _1, _2) => {
          const spent = row.spent;
          const planned = row.planned;
          const budget = row.budget;
          const spent_percentage =
            spent === 0 || budget === 0
              ? 0
              : ((spent + planned) / budget) * 100;
          return Math.round(spent_percentage);
        },
        valueFormatter: (value: number) =>
          !value || value === 0 ? '' : value + '%',
      },
    ],
    [props.projectData]
  );

  const spent_percentage: GridAggregationFunction<
    { budget: number; spent: number },
    number
  > = {
    label: 'spent_percentage',
    getCellValue: ({ row }) => ({ budget: row.budget, spent: row.spent }),
    apply: ({ values }) => {
      let budget = 0;
      let spent = 0;
      values.forEach((value) => {
        if (value) {
          spent += value.spent;
          budget += value.budget;
        }
      });

      return Math.round((spent / budget) * 100);
    },
    columnTypes: ['number'],
  };

  const total_percentage: GridAggregationFunction<
    { budget: number; total: number },
    number
  > = {
    label: 'total_percentage',
    getCellValue: ({ row }) => ({ budget: row.budget, total: row.total }),
    apply: ({ values }) => {
      let budget = 0;
      let total = 0;
      values.forEach((value) => {
        if (value) {
          total += value.total;
          budget += value.budget;
        }
      });

      return Math.round((total / budget) * 100);
    },
    columnTypes: ['number'],
  };
  return (
    <BudgetTableDiv>
      <DataGridPremium
        rows={tableRows}
        columns={columns}
        rowHeight={30}
        columnHeaderHeight={30}
        disableRowSelectionOnClick
        rowSelection={false}
        hideFooter={true}
        disableColumnFilter
        cellSelection
        aggregationFunctions={{
          ...GRID_AGGREGATION_FUNCTIONS,
          spent_percentage,
          total_percentage,
        }}
        initialState={{
          aggregation: {
            model: {
              budget: 'sum',
              spent: 'sum',
              spent_percentage: 'spent_percentage',
              planned: 'sum',
              total: 'sum',
              total_percentage: 'total_percentage',
            },
          }, // Add sum aggregation row
        }}
        sx={{
          border: 0,
          '& .MuiDataGrid-main': {
            border: 'solid 1px var(--twd_border_grey)',
          },
          '& .MuiDataGrid-cell': { padding: '0 5px' },
          '& .MuiInputBase-input': { padding: '0 5px' },
          '& .MuiDataGrid-columnHeader': {
            color: 'white',
            background: 'var(--twd_aqua)',
          },
          '& .MuiDataGrid-columnHeaders .MuiDataGrid-filler': {
            color: 'white',
            background: 'var(--twd_aqua)',
          },
          '.MuiDataGrid-footerCell': {
            color: 'var(--twd_navy)',
            fontWeight: 'bold',
          },
          '& .MuiDataGrid-columnHeaders .MuiDataGrid-columnSeparator': {
            color: 'white',
          },
          '& .MuiDataGrid-scrollbar': {
            zIndex: 1,
          },
          '& .MuiDataGrid-columnHeaders .MuiDataGrid-scrollbarFiller': {
            background: 'var(--twd_aqua)',
          },
          '& .MuiDataGrid-aggregationColumnHeaderLabel': {
            display: 'none',
          },
        }}
      />
    </BudgetTableDiv>
  );
}

const BudgetTableDiv = styled.div`
  width: calc(50% - 45px);
  height: calc(100% - 40px);
  padding: 20px;
  margin-right: 5px;
  background-color: white;
  border-radius: 8px;
  box-shadow: inset 0 0 0 1px var(--twd_web_grey);

  @media (max-width: 1250px) {
    width: calc(100% - 40px);
    height: calc(50% - 40px);
    margin-bottom: 10px;
    margin-right: 0;
  }
`;
