import { WeekSelectProps } from './WeekSelect.types';
import { useEffect, useState } from 'react';
import { changeDateToWeek } from '../../Functions/TimeFunctions';
import { addDays, isBefore, startOfWeek } from 'date-fns';
import { AutoComplete } from '../../../AutoComplete/AutoComplete';
import { styled } from 'styled-components';

const WeekSelectDiv = styled.div`
  width: 150px;
  height: 40px;
  background: white;
`;

export function WeekSelect(props: WeekSelectProps) {
  const [weeks, setWeeks] = useState<Date[]>([]);
  useEffect(() => {
    const currentDate = new Date();
    let beginDate = new Date(props.projectData.startDate);
    const newWeekList = [];
    while (isBefore(beginDate, currentDate) || beginDate === currentDate) {
      newWeekList.push(beginDate);
      const weekStart = startOfWeek(beginDate, { weekStartsOn: 1 });
      beginDate = addDays(weekStart, 7);
    }
    setWeeks(newWeekList.reverse());
  }, [props.projectData]);

  return (
    <WeekSelectDiv>
      <AutoComplete
        options={weeks}
        optionLabels={(week: Date) => changeDateToWeek(week)}
        onChange={(value) => {
          if (value) {
            props.setSelectedWeek(value);
            props.changePlanningSettings(value, props.weekView);
          }
        }}
        placeholder={'Select week'}
        autoSelectIndex={0}
        disabled={props.projectData.projectId === ''}
        alwaysRerender={true}
      />
    </WeekSelectDiv>
  );
}
