import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import {
  PhaseData,
  PlanningData,
  PlanningSettings,
  PlanningType,
  RowEntry,
  WeekData,
} from './PlanningType';
import { createPlanningEntries } from './Functions/DatabaseFunctions';
import { GridColDef, GridRowId, useGridApiRef } from '@mui/x-data-grid-premium';
import {
  ActualCostingEntry,
  CostEstimateEntry,
  ProjectType,
} from '../../App.types.';
import { addDays, isBefore, startOfWeek } from 'date-fns';
import { fromStringToDate } from './Functions/TimeFunctions';
import {
  generateColumnsForWeek,
  getMaxPlannedDate,
  getWeekColumns,
} from './PlanningTable/Columns';

export const useProjectData = (projectData: ProjectType) => {
  const [phaseData, setPhaseData] = useState<PhaseData>({});
  const [weekData, setWeekData] = useState<WeekData>({});

  useEffect(() => {
    const newPhaseData: PhaseData = {};
    const newWeekData: WeekData = {};
    projectData.actualCosting.forEach((actualCosting: ActualCostingEntry) => {
      const phaseCode = actualCosting.PhaseCode;
      const yearWeek = actualCosting.YearWeek;
      if (!newPhaseData[phaseCode]) {
        newPhaseData[phaseCode] = { actualCosting: 0, costEstimate: 0 };
      }
      if (!newWeekData[yearWeek]) {
        newWeekData[yearWeek] = { actualCosting: 0, costEstimate: 0 };
      }
      newPhaseData[phaseCode].actualCosting += actualCosting.SalesValue;
      newWeekData[yearWeek].actualCosting += actualCosting.SalesValue;
    });

    projectData.costEstimate.forEach((costEstimateEntry: CostEstimateEntry) => {
      const phaseCode = costEstimateEntry.PhaseCode;
      const yearWeek = costEstimateEntry.YearWeek;
      if (!newPhaseData[phaseCode]) {
        newPhaseData[phaseCode] = { actualCosting: 0, costEstimate: 0 };
      }
      if (!newWeekData[yearWeek]) {
        newWeekData[yearWeek] = { actualCosting: 0, costEstimate: 0 };
      }
      newPhaseData[phaseCode].costEstimate += costEstimateEntry.SalesValue;
      newWeekData[yearWeek].costEstimate += costEstimateEntry.SalesValue;
    });

    setPhaseData(newPhaseData);
    setWeekData(newWeekData);
  }, [projectData]);

  return { phaseData, weekData };
};

export const usePlanningData = (
  projectData: ProjectType,
  selectedPlanning: PlanningType | null,
  setIsLoading: (isLoading: boolean) => void,
  inEditMode: boolean
) => {
  const [planningData, setPlanningData] = useState<PlanningData>({
    rows: [],
    lastDate: new Date(),
    weekColumns: [],
  });
  useEffect(() => {
    const getPlanningData = async () => {
      setIsLoading(true);
      let rows: RowEntry[] = [];
      let weekColumns: GridColDef<RowEntry>[] = [];
      let lastDate = new Date();
      if (selectedPlanning && Object.keys(projectData.phases).length !== 0) {
        const planningEntryData = await createPlanningEntries(
          selectedPlanning,
          projectData.phases
        );
        rows = planningEntryData[0];
        lastDate = planningEntryData[1];
        weekColumns = getWeekColumns(
          projectData,
          getMaxPlannedDate(planningEntryData[0]),
          inEditMode
        );
      }
      setPlanningData({
        rows: rows,
        lastDate: lastDate,
        weekColumns: weekColumns,
      });
      setIsLoading(false);
    };

    void getPlanningData();
  }, [selectedPlanning, inEditMode]);

  return { planningData };
};

export const usePlanningUpdates = (
  projectData: ProjectType,
  planningData: PlanningData,
  setUpdatedRows: Dispatch<SetStateAction<RowEntry[]>>,
  setChangeIsMade: Dispatch<SetStateAction<boolean>>
) => {
  const apiRef = useGridApiRef();
  let idCounter = planningData.rows.length + 1;
  let lastWeek = startOfWeek(planningData.lastDate, { weekStartsOn: 1 });
  const isWeekClicked = useRef(true);
  const selectedDate = useRef(new Date());

  useEffect(() => {
    changePlanningSettings(selectedDate.current, isWeekClicked.current);
  }, [planningData]);

  function addRow(id: GridRowId) {
    const clickedRow: RowEntry | null = apiRef.current.getRow(id);
    if (clickedRow) {
      apiRef.current.updateRows([
        {
          partId: clickedRow.partId,
          part: clickedRow.part,
          id: idCounter,
          rate: 'E',
          employee: '',
          task: 'Enter description here',
        },
      ]);
      setUpdatedRows((oldUpdatedRows) => [
        ...oldUpdatedRows,
        {
          partId: clickedRow.partId,
          part: clickedRow.part,
          id: idCounter,
          rate: 'E',
          employee: '',
          task: 'Enter description here',
        },
      ]);
      idCounter += 1;
    }
  }

  function deleteRow(id: GridRowId) {
    const clickedRow: RowEntry | null = apiRef.current.getRow(id);
    if (clickedRow) {
      const rowsModels = apiRef.current.getRowModels();
      const rows: RowEntry[] = Array.from(rowsModels.values()) as RowEntry[];
      const phaseRows = rows.filter((row) => row.partId === clickedRow.partId);
      if (phaseRows.length < 2) {
        addRow(id);
      }
      apiRef.current.updateRows([{ id: id, _action: 'delete' }]);
      setUpdatedRows((oldUpdatedRows: RowEntry[]) =>
        oldUpdatedRows.filter(
          (oldUpdatedRow: RowEntry) => oldUpdatedRow.id !== id
        )
      );
      setChangeIsMade(true);
    }
  }

  function copyRow(id: GridRowId) {
    const clickedRow: RowEntry | null = apiRef.current.getRow(id);
    if (clickedRow) {
      const { id, ...rest } = clickedRow;
      apiRef.current.updateRows([{ id: idCounter, ...rest }]);
      setUpdatedRows((oldUpdatedRows) => [
        ...oldUpdatedRows,
        { id: idCounter, ...rest },
      ]);
      setChangeIsMade(true);
      idCounter += 1;
    }
  }

  function addColumn() {
    const columns = apiRef.current.getAllColumns();
    lastWeek = addDays(lastWeek, 7);
    const newWeekColumns = getWeekColumns(projectData, lastWeek, true);
    apiRef.current.updateColumns([...columns, ...newWeekColumns]);
    changePlanningSettings(selectedDate.current, isWeekClicked.current);
  }

  function changePlanningSettings(date: Date, weekView: boolean) {
    isWeekClicked.current = weekView;
    selectedDate.current = date;
    const columns = apiRef.current.getAllColumns();
    if (columns.length > 0) {
      const currentWeek = startOfWeek(date, { weekStartsOn: 1 });
      const planningSettings = columns.reduce(
        (acc: PlanningSettings, column: GridColDef) => {
          const fieldName = startOfWeek(fromStringToDate(column.field), {
            weekStartsOn: 1,
          });
          const className: string = (column.cellClassName as string) || '';
          if (className.includes('week')) {
            acc.aggregation[column.field] = 'valueWeek';
          }
          if (isBefore(fieldName, currentWeek)) {
            acc.visibility[column.field] = false;
          } else if (['M', 'T', 'W', 'F'].includes(column.headerName || '')) {
            acc.visibility[column.field] = !weekView;
          }
          return acc;
        },
        { visibility: {}, aggregation: {} }
      );
      apiRef.current.setAggregationModel({
        planned: 'valueWeek',
        total: 'valueWeek',
        ...planningSettings.aggregation,
      });
      apiRef.current.setColumnVisibilityModel({
        part: false,
        ...planningSettings.visibility,
      });
    }
  }

  return {
    apiRef,
    addRow,
    deleteRow,
    copyRow,
    addColumn,
    changePlanningSettings,
  };
};
