import React, { MouseEvent, useState } from 'react';
import { PlanningType } from '../../../PlanningType';
import { styled } from 'styled-components';
import { ReactComponent as CrossIcon } from '../../../../../assets/Cross.svg';
import { ReactComponent as CopyIcon } from '../../../../../assets/Copy.svg';
import { ReactComponent as EditIcon } from '../../../../../assets/Edit.svg';
import { PlanningButtonContentProps } from './PlanningButtonOperations.types';
import { IconButton } from '../../../../IconButton/IconButton';
import { WarningModal } from '../../../../Modals/WarningModal';

export function PlanningButtonOperations<T extends object>(
  props: PlanningButtonContentProps<T>
) {
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

  const closeModal = () => {
    setOpenDeleteModal(false);
  };
  const onEditButtonClicked = (event: MouseEvent<HTMLElement>) => {
    props.updateEditModalData(props.option.option as PlanningType);
    event.stopPropagation();
  };
  const clickOnCopyPlanning = async (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    await props.copyPlanning(props.option.option as PlanningType);
    props.changeSelectedOption(-1);
  };

  const clickOnDeleteIcon = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setOpenDeleteModal(true);
  };
  const clickOnDeletePlanning = async () => {
    await props.deletePlanning(props.option.option as PlanningType);
    props.changeSelectedOption(0);
  };

  return (
    <IconButtonDiv>
      <WarningModal
        open={openDeleteModal}
        closeModal={closeModal}
        deletePlanning={clickOnDeletePlanning}
        planningName={(props.option.option as PlanningType).name}
      />

      <IconButton hoverColor={'var(--twd_aqua)'} onClick={onEditButtonClicked}>
        <EditIcon />
      </IconButton>
      <IconButton
        hoverColor={'var(--twd_aqua)'}
        onClick={async (e: MouseEvent<HTMLElement>) =>
          await clickOnCopyPlanning(e)
        }
      >
        <CopyIcon />
      </IconButton>
      <IconButton hoverColor={'var(--twd_red)'} onClick={clickOnDeleteIcon}>
        <CrossIcon />
      </IconButton>
    </IconButtonDiv>
  );
}

const IconButtonDiv = styled.div`
  display: flex;
  align-items: center;
  z-index: 11;
`;
