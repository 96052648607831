import { ThemeProvider } from '@mui/material';
import { twdTheme } from './styles/CustomMaterialTheme';
import { styled } from 'styled-components';
import React, { useEffect, useState } from 'react';
import { Planning } from './components/Planning/Planning';
import { Navbar, getUserProps } from 'twd-react-components';
import { ReportPage } from './components/Report/ReportPage';
import { ProjectDataType } from './components/Report/LeftColumn/InputType';
import { getFromAPI } from './services/apiCalls';
import { PlanningType } from './components/Planning/PlanningType';
import { CircleLoader } from './components/CircleLoader/CircleLoader';
import { ChangeAppBar } from './components/ChangeAppBar/ChangeAppBar';
import { useEditMode } from './App.hooks';
import { ProjectType, UserType } from './App.types.';

const ScreenDiv = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`;

// Styling for the Box containing the whole app.
const AppDiv = styled.div`
  position: relative;
  height: calc(100% - 85px);
  margin-top: 50px;
  padding: 10px 30px 30px 30px;
  background-color: var(--twd_grey);
  overflow: auto;
`;

const FunctionPlanningDiv = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 1250px) {
    flex-direction: column;
    overflow: auto;
    height: unset;
  }
`;

const LoaderDiv = styled.div`
  width: 100vw;
  height: calc(100vh - 50px);
  margin-top: 50px;
  z-index: 100;
  background-color: var(--twd_grey);
  opacity: 0.7;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function App() {
  const { profileName } = getUserProps();

  const [currentUser, setCurrentUser] = useState<UserType | null>(null);
  const [projects, setProjects] = useState<ProjectDataType[]>([]);
  const [finishedLoadingAppData, setFinishedLoadingAppData] =
    useState<boolean>(false);

  useEffect(() => {
    const loadAppData = async () => {
      let userInfo: UserType | null = null;
      const userResponse = await getFromAPI(`/afas/employee/${profileName}`);
      if (userResponse.ok) {
        const userInfoList: UserType[] = (await userResponse.json()).data;
        userInfo = userInfoList.length > 0 ? userInfoList[0] : null;
      }
      const projectsResponse = await getFromAPI('/budget-report/projects');
      const loadedProjects: ProjectDataType[] = (await projectsResponse.json())
        .data;

      const sortedProjects = loadedProjects.sort((a, b) =>
        (a.ProjectNumber + ' ' + a.ProjectName).localeCompare(
          b.ProjectNumber + ' ' + b.ProjectName
        )
      );
      setCurrentUser(userInfo);
      setProjects(sortedProjects);
      setFinishedLoadingAppData(true);
    };

    void loadAppData();
  }, [profileName]);

  const [selectedTab, setSelectedTab] = useState<boolean>(true);
  const [selectedPlanning, setSelectedPlanning] = useState<PlanningType | null>(
    null
  );
  const [plannings, setPlannings] = useState<PlanningType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { inEditMode, alreadyInUse, enterEditMode, leaveEditMode } =
    useEditMode(selectedPlanning, setIsLoading, currentUser);

  const [selectedProject, setSelectedProject] =
    useState<ProjectDataType | null>(null);
  const [projectData, setProjectData] = useState<ProjectType>({
    projectId: '',
    projectNumber: '',
    currency: '',
    startDate: '',
    phases: {},
    rates: {},
    actualCosting: [],
    costEstimate: [],
  });

  useEffect(() => {
    const GetAllProjectData = async () => {
      if (selectedProject) {
        setIsLoading(true);
        const projectId = selectedProject.ProjectId;

        // Initialize the phases
        const phasesResponse = await getFromAPI(
          '/afas/projects/phases/' + projectId
        );
        const allPhases = (await phasesResponse.json()).data;

        // Initialize the rates
        const ratesResponse = await getFromAPI(
          '/afas/projects/rates/' + projectId
        );
        const allRates = (await ratesResponse.json()).data;

        // Initialize the actual costing
        const actualCostingResponse = await getFromAPI(
          '/afas/projects/actual_costing/' + projectId
        );
        const actualCosting = (await actualCostingResponse.json()).data;

        // Initialize the cost estimates
        const costEstimateResponse = await getFromAPI(
          '/afas/projects/cost-estimate/' + projectId
        );
        const costEstimates = (await costEstimateResponse.json()).data;

        // Initialize the plannings
        const planningsResponse = await getFromAPI(
          '/budget-planning/' + projectId
        );
        const allPlannings = (await planningsResponse.json()).data;
        setPlannings(allPlannings);
        setProjectData({
          projectId: projectId,
          projectNumber: selectedProject.ProjectNumber,
          currency: selectedProject.CurrencySymbol,
          startDate: selectedProject.StartDate,
          phases: allPhases,
          rates: allRates,
          actualCosting: actualCosting,
          costEstimate: costEstimates,
        });
        setIsLoading(false);
      }
    };
    void GetAllProjectData();
  }, [selectedProject]);

  return (
    <ThemeProvider theme={twdTheme}>
      <ScreenDiv>
        <Navbar title={selectedTab ? 'Budget Planning' : 'Budget Report'} />
        {isLoading && (
          <LoaderDiv>
            <CircleLoader />
          </LoaderDiv>
        )}
        {finishedLoadingAppData && (
          <AppDiv>
            <ChangeAppBar
              projects={projects}
              setSelectedProject={setSelectedProject}
              isPlanningSelected={selectedTab}
              setSelectedTab={setSelectedTab}
              inEditMode={inEditMode}
            ></ChangeAppBar>
            {selectedTab ? (
              <FunctionPlanningDiv>
                <Planning
                  projectData={projectData}
                  selectedPlanning={selectedPlanning}
                  setSelectedPlanning={setSelectedPlanning}
                  allPlannings={plannings}
                  setAllPlannings={setPlannings}
                  setIsloading={setIsLoading}
                  inEditMode={inEditMode}
                  alreadyInUse={alreadyInUse}
                  enterEditMode={enterEditMode}
                  leaveEditMode={leaveEditMode}
                  currentUser={currentUser}
                />
              </FunctionPlanningDiv>
            ) : (
              <ReportPage projectData={projectData} plannings={plannings} />
            )}
          </AppDiv>
        )}
      </ScreenDiv>
    </ThemeProvider>
  );
}

export default App;
