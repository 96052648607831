import { styled } from 'styled-components';

interface ToggleButtonsProps {
  firstButtonText: string;
  secondButtonText: string;
  value: boolean;
  onChange: (p: boolean) => void;
  width?: string;
}

export function ToggleButtons(props: ToggleButtonsProps) {
  return (
    <ToggleButtonsDiv width={props.width}>
      <LeftButton
        isSelected={props.value}
        disabled={props.value}
        onClick={() => props.onChange(true)}
      >
        {props.firstButtonText}
      </LeftButton>
      <RightButton
        isSelected={!props.value}
        disabled={!props.value}
        onClick={() => props.onChange(false)}
      >
        {props.secondButtonText}
      </RightButton>
    </ToggleButtonsDiv>
  );
}

const ToggleButtonsDiv = styled.div<{ width?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: ${(props) => (props.width ? props.width : '213px')};
  padding: 0;
  margin: 0;
`;

const ToggleButton = styled.button<{ isSelected: boolean }>`
  font: inherit;
  height: 100%;
  width: 50%;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: ${(props) => (props.isSelected ? 'bold' : 'initial')};
  cursor: ${(props) => (props.isSelected ? 'default' : 'pointer')};
  color: ${(props) => (props.isSelected ? 'white' : 'var(--twd_aqua)')};
  background: ${(props) => (props.isSelected ? 'var(--twd_aqua)' : 'white')};
  box-shadow: inset 0 0 0 2px var(--twd_aqua);
  z-index: ${(props) => (props.isSelected ? 2 : 1)};

  &:hover {
    background: ${(props) => !props.isSelected && 'var(--twd_aqua_pale_2)'};
  }
`;

const LeftButton = styled(ToggleButton)`
  border-radius: 8px 0 0 8px;
`;

const RightButton = styled(ToggleButton)`
  border-radius: 0 8px 8px 0;
`;
