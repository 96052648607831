import { styled } from 'styled-components';
import { ReactComponent as SaveIcon } from '../../assets/Save.svg';
import { ReactComponent as EditIcon } from '../../assets/Edit.svg';
import { ReactComponent as LockIcon } from '../../assets/Lock.svg';
import { PlanningType, RowEntry } from './PlanningType';
import { UserType } from '../../App.types.';
import useScrollbarSize from 'react-scrollbar-size';
import { Tooltip } from '@mui/material';

interface EditModeButtonsProps {
  updatedRows: RowEntry[];
  changeIsMade: boolean;
  setChangeIsMade: (changeIsMade: boolean) => void;
  selectedPlanning: PlanningType;
  inEditMode: boolean;
  alreadyInUse: string;
  setIsloading: (isLoading: boolean) => void;
  enterEditMode: (selectedPlanning: PlanningType) => Promise<void>;
  leaveEditMode: (selectedPlanning: PlanningType) => Promise<void>;
  setOpenNotSavedModal: (openNotSaveModal: boolean) => void;
  currentUser: UserType | null;
  savePlanning: (planningId: string) => Promise<void>;
}

export function EditModeButtons(props: EditModeButtonsProps) {
  const { width } = useScrollbarSize();

  return (
    <>
      <EditModeSection scrollbarWidth={width}>
        <ButtonsDiv>
          <Tooltip
            title={props.inEditMode ? 'Leave edit mode' : 'Enter edit mode'}
            placement={'top'}
          >
            <StyledButton
              onClick={async () => {
                if (props.inEditMode) {
                  if (props.changeIsMade) {
                    props.setOpenNotSavedModal(true);
                  } else {
                    await props.leaveEditMode(props.selectedPlanning);
                  }
                } else {
                  await props.enterEditMode(props.selectedPlanning);
                }
              }}
              disabled={props.alreadyInUse !== ''}
            >
              {props.alreadyInUse !== '' ? (
                <LockIcon />
              ) : props.inEditMode ? (
                'Leave edit'
              ) : (
                <EditIcon style={{ fill: 'white' }} />
              )}
            </StyledButton>
          </Tooltip>
          <Tooltip title={'Save planning'} placement={'top'}>
            <StyledButton
              changeIsMade={props.inEditMode && props.changeIsMade}
              onClick={() => props.savePlanning(props.selectedPlanning.id)}
              disabled={!props.inEditMode || !props.changeIsMade}
            >
              <SaveIcon />
            </StyledButton>
          </Tooltip>
        </ButtonsDiv>
      </EditModeSection>
    </>
  );
}

const EditModeSection = styled.div<{ scrollbarWidth: number }>`
  position: sticky;
  bottom: 0;
  margin-top: 20px;
  display: flex;
  justify-self: end;
  z-index: 10;
`;

const ButtonsDiv = styled.div`
  display: flex;
  justify-content: space-between;
  width: 160px;
  z-index: 10;
`;

const StyledButton = styled.button<{ changeIsMade?: boolean }>`
  width: 70px;
  height: 70px;
  border-radius: 8px;
  background: var(--twd_aqua);
  color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
  svg {
    width: 50px;
    height: 36px;
  }
  
  &:hover {
    background: #0091c9
  }
  
  &:disabled {
    cursor: initial;
    background: var(--twd_web_grey);
  }
  
  animation: ${(props) => props.changeIsMade && 'changeAnimation 1s cubic-bezier(.24,0,.38,1) infinite'};
  
  @keyframes changeAnimation {
    0%, 100% {
      opacity: 1,
    }
    50% {
      opacity: 0.5
    }
  },
`;
