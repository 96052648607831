import { Modal, StyledButton } from './Modal';
import styled from 'styled-components';
import React from 'react';
import { ReactComponent as AlertIcon } from '../../assets/Alert.svg';
interface WarningModalProps {
  open: boolean;
  closeModal: () => void;
  deletePlanning: () => Promise<void>;
  planningName: string;
}

export function WarningModal(props: WarningModalProps) {
  return (
    <Modal
      open={props.open}
      closeModal={props.closeModal}
      headerText={
        <>
          <AlertIcon
            style={{ width: '22px', height: '22px', marginRight: '10px' }}
          />
          Warning
        </>
      }
    >
      <TextDiv>
        Are you sure you want to delete planning <b>{props.planningName}</b>{' '}
        <br />
        This action cannot be undone!
      </TextDiv>
      <ButtonDiv>
        <CancelButton onClick={props.closeModal}>Go Back</CancelButton>
        <ContinueButton onClick={async () => await props.deletePlanning()}>
          Delete
        </ContinueButton>
      </ButtonDiv>
    </Modal>
  );
}

const TextDiv = styled.div`
  width: 100%;
  height: 40px;
  text-align: left;
  border: 0;
  outline: none;
  font: inherit;
  background: inherit;
  color: black;
  margin-bottom: 20px;
`;

const ButtonDiv = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: right;
`;

const CancelButton = styled(StyledButton)`
  width: 80px;
  height: 40px;
  background-color: white;
  box-shadow: inset 0 0 0 1.5px var(--twd_web_grey);
  color: var(--twd_web_grey);
  margin-right: 20px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 8px;
  &:hover {
    transform: none;
    background-color: var(--twd_grey);
    box-shadow: inset 0 0 0 1.5px var(--twd_grey);
  }
`;

const ContinueButton = styled(StyledButton)`
  width: 80px;
  height: 40px;
  background-color: var(--twd_signal);
  box-shadow: inset 0 0 0 1.5px var(--twd_signal);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    transform: none;
  }
`;
