import React from 'react';
import { styled } from 'styled-components';
import { AutoCompleteButtonProps } from './AutCompleteButton.types.';
import { ReactComponent as IconTick } from '../../../assets/Icon_Tick.svg';

export function AutoCompleteButton<T extends object>(
  props: AutoCompleteButtonProps<T>
) {
  const StyledButton = props.buttonStyle || StyledAutoCompleteButton;

  return (
    <>
      <StyledButton
        ref={(el: any) => (props.buttonRefs.current[props.option.id] = el)}
        onClick={() => props.changeSelectedOption(props.option.id)}
        isHoveringOver={
          props.hoverOverOption && props.hoverOverOption.id === props.option.id
        }
        disabled={
          props.disableOption ? props.disableOption(props.option.option) : false
        }
      >
        <StyledButtonContentDiv>
          {props.option.displayedValue}
          {props.extraButtonContent && <props.extraButtonContent {...props} />}
        </StyledButtonContentDiv>
        {props.isOptionSelected && <IconTick style={{ width: '25px' }} />}
      </StyledButton>
    </>
  );
}

export const StyledAutoCompleteButton = styled.button<{
  isHoveringOver?: boolean;
}>`
  width: 95%;
  height: 45px;
  background: inherit;
  border: 0;
  position: relative;

  z-index: 3;
  text-align: left;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: ${(props) =>
    props.isHoveringOver &&
    !props.disabled &&
    'inset 0 0 0 1.5px var(--twd_aqua)'};

  &:hover {
    box-shadow: ${(props) =>
      !props.disabled && 'inset 0 0 0 1.5px var(--twd_aqua)'};
    cursor: ${(props) => (!props.disabled ? 'pointer' : 'initial')};
  }

  &:focus-visible {
    border: 0;
  }
`;

const StyledButtonContentDiv = styled.div`
  width: calc(100% - 30px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 3;
`;
