import { Modal, StyledButton } from './Modal';
import React from 'react';
import styled from 'styled-components';

interface CreationModalProps {
  open: boolean;
  closeModal: () => void;
  headerText: string;
  blueButtonText: string;
  value?: string;
  setValue: (value: string) => void;
  createPlanning: () => Promise<void>;
}

export function CreationModal(props: CreationModalProps) {
  const changeValue = (event: any) => {
    props.setValue(event.target.value);
  };

  return (
    <Modal
      open={props.open}
      closeModal={props.closeModal}
      headerText={props.headerText}
    >
      <StyledInput
        type="text"
        value={props.value}
        placeholder={'Enter Planning Name'}
        onChange={changeValue}
      />
      <ButtonDiv>
        <CancelButton onClick={props.closeModal}>Cancel</CancelButton>
        <CreateButton onClick={props.createPlanning}>Create</CreateButton>
      </ButtonDiv>
    </Modal>
  );
}

const StyledInput = styled.input<{ isInputFieldSelected?: boolean }>`
  width: calc(100% - 20px);
  height: calc(40px - 20px);
  padding: 10px;
  text-align: left;
  border: 0;
  outline: none;
  font: inherit;
  background: inherit;
  color: black;
  box-shadow: 0 0 0 1.5px var(--twd_web_grey);
  border-radius: 8px;
  margin-bottom: 20px;

  &::placeholder {
    color: lightgray;
  }
`;

const ButtonDiv = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: right;
`;

const CancelButton = styled(StyledButton)`
  width: 80px;
  height: 40px;
  background-color: white;
  box-shadow: inset 0 0 0 1.5px var(--twd_web_grey);
  color: var(--twd_web_grey);
  margin-right: 20px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 8px;
  &:hover {
    transform: none;
    background-color: var(--twd_grey);
    box-shadow: inset 0 0 0 1.5px var(--twd_grey);
  }
`;

const CreateButton = styled(StyledButton)`
  width: 80px;
  height: 40px;
  background-color: var(--twd_aqua);
  box-shadow: inset 0 0 0 1.5px var(--twd_aqua);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    transform: none;
  }
`;
