import { styled } from 'styled-components';
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { AutoCompleteModal } from './AutoCompleteModal/AutoCompleteModal';
import { AutoCompleteOption, AutoCompleteProps } from './AutoComplete.types';
import { AutoCompleteInput } from './AutoCompleteInput/AutoCompleteInput';

/**
 * General component that creates a dropdown menu coupled with a search bar in which the user can type in to search
 * for their preferred option. Based on the AutoComplete component of mui: https://mui.com/material-ui/react-autocomplete/
 */
export function AutoComplete<T extends object>(props: AutoCompleteProps<T>) {
  const [filteredList, setFilteredList] = useState<AutoCompleteOption<T>[]>([]);
  const [inputValue, setInputValue] = useState<string>('');
  const [isOptionChosen, setIsOptionChosen] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] =
    useState<AutoCompleteOption<T> | null>(null);
  const [autoSelectIndex, setAutoSelectIndex] = useState<number | undefined>(
    props.autoSelectIndex
  );
  const [hoverOverOption, sethoverOverOption] =
    useState<AutoCompleteOption<T> | null>(null);

  const [hoverIndex, setHoverIndex] = useState<number>(0);

  // Utility function to map options to AutoCompleteOption array
  const mapOptions = useCallback(
    (options: T[]): AutoCompleteOption<T>[] => {
      return options.map((option: T, index: number) => ({
        id: index,
        option,
        displayedValue: props.optionLabels(option),
      }));
    },
    [props.optionLabels]
  );

  const autoCompleteOptions = useMemo(
    () => mapOptions(props.options),
    [props.options]
  );

  const inputRef = useRef(null);

  // Handle input selection (option chosen)
  const changeSelectedOption = useCallback(
    (index: number | undefined) => {
      const idIndexValid = index !== undefined;
      let autoCompleteOption = null;
      if (idIndexValid) {
        setAutoSelectIndex(index);
        const formattedIndex =
          index === -1 ? autoCompleteOptions.length - 1 : index;
        autoCompleteOption = autoCompleteOptions[formattedIndex];
      }
      setInputValue(
        autoCompleteOption ? autoCompleteOption.displayedValue : ''
      );
      setSelectedOption(autoCompleteOption);
      props.onChange(autoCompleteOption && autoCompleteOption.option);
      setIsOptionChosen(idIndexValid);
      setOpenModal(false);
      (inputRef.current as any).blur();
    },
    [autoCompleteOptions, props.onChange]
  );

  useEffect(() => {
    autoCompleteOptions.length > 0
      ? props.alwaysRerender
        ? changeSelectedOption(props.autoSelectIndex)
        : changeSelectedOption(autoSelectIndex)
      : changeSelectedOption(undefined);
    setAutoSelectIndex(props.autoSelectIndex);
  }, [autoCompleteOptions]);

  // Function to filter options based on the input value
  useEffect(() => {
    const newList = isOptionChosen
      ? autoCompleteOptions
      : autoCompleteOptions.filter((option: AutoCompleteOption<T>) =>
          option.displayedValue.toLowerCase().includes(inputValue.toLowerCase())
        );
    setFilteredList(newList);
  }, [autoCompleteOptions, inputValue, isOptionChosen]);

  // Handle click outside of the input to close the dropdown
  const ref = useOutsideClick(() => {
    setOpenModal(false);
    setInputValue(selectedOption ? selectedOption.displayedValue : '');
    setIsOptionChosen(true);
  });

  const changeInputOnTyping = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    setIsOptionChosen(false);
  };

  const buttonRefs = useRef<(HTMLButtonElement | null)[]>([]);

  const handleKeyDown = (event: any) => {
    if (event.key === 'ArrowDown') {
      if (hoverOverOption) {
        const newIndex = hoverIndex + 1;
        sethoverOverOption(filteredList[newIndex]);
        setHoverIndex(newIndex);
        const hoveredButton = buttonRefs.current[newIndex];
        if (hoveredButton) {
          hoveredButton.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'center',
          });
        }
      } else {
        sethoverOverOption(filteredList[0]);
        setHoverIndex(0);
      }
    } else if (event.key === 'ArrowUp') {
      if (hoverOverOption) {
        const newIndex = hoverIndex - 1;
        sethoverOverOption(filteredList[newIndex]);
        setHoverIndex(newIndex);
        const hoveredButton = buttonRefs.current[newIndex];
        if (hoveredButton) {
          hoveredButton.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'center',
          });
        }
      }
    } else if (event.key === 'Enter') {
      if (hoverOverOption) {
        changeSelectedOption(hoverOverOption.id);
      }
    }
  };

  useEffect(() => {
    sethoverOverOption(null);
  }, [inputValue]);

  useEffect(() => {
    !openModal && sethoverOverOption(null);
  }, [openModal]);

  return (
    <AutoCompleteDiv
      className="AutoComplete"
      ref={ref}
      onKeyDown={handleKeyDown}
    >
      <AutoCompleteInput
        inputValue={inputValue}
        openModal={openModal}
        setOpenModal={setOpenModal}
        placeholder={props.placeholder}
        disabled={props.disabled}
        NoPlaceholderAnimation={props.NoPlaceholderAnimation}
        changeInputOnTyping={changeInputOnTyping}
        inputFieldStyle={props.inputFieldStyle}
        placeholderColor={props.placeholderColor}
        arrowStyle={props.arrowStyle}
        inputRef={inputRef}
      />
      {openModal && (
        <AutoCompleteModal
          filteredList={filteredList}
          selectedOption={selectedOption}
          changeSelectedOption={changeSelectedOption}
          extraModalSection={props.extraModalSection}
          extraButtonContent={props.extraButtonContent}
          modalStyle={props.modalStyle}
          buttonStyle={props.buttonStyle}
          hoverOverOption={hoverOverOption}
          setHoverIndex={setHoverIndex}
          buttonRefs={buttonRefs}
          disableOption={props.disableOption}
        />
      )}
    </AutoCompleteDiv>
  );
}

export const useOutsideClick = (callback: () => void) => {
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [callback]);

  return ref;
};

export const AutoCompleteDiv = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: inline-block;
  background: inherit;
`;
