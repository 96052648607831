import React, { useEffect, useRef, useState } from 'react';
import '../../styles/Planning.css';
import { LicenseInfo } from '@mui/x-license';
import { styled } from 'styled-components';
import { PlanningProps, RowEntry } from './PlanningType';
import { Graph } from './Graph/Graph';
import { BudgetTable } from './BudgetTable/BudgetTable';

import { useProjectData, usePlanningData } from './Planning.hooks';
import { EditModeButtons } from './EditModeButtons';
import { PlanningTable } from './PlanningTable/PlanningTable';
import { SaveModal } from '../Modals/SaveModal';
import { convertToDatabase } from './Functions/DatabaseFunctions';
import { postFromAPI, putFromAPI } from '../../services/apiCalls';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LISCENSE_KEY as string);

const StyledDiv = styled.div`
  width: 100%;
  @media (max-width: 1250px) {
    flex-direction: column;
  }
`;

const ColumnDiv = styled.div`
  display: flex;
  height: 400px;
  margin-bottom: 10px;
  @media (max-width: 1250px) {
    flex-direction: column;
    height: 800px;
    margin-bottom: 0;
  }
`;

export function Planning(props: PlanningProps) {
  const shouldTriggerAction = useRef(false);
  const { phaseData, weekData } = useProjectData(props.projectData);
  const { planningData } = usePlanningData(
    props.projectData,
    props.selectedPlanning,
    props.setIsloading,
    props.inEditMode
  );
  const [updatedRows, setUpdatedRows] = useState<RowEntry[]>([]);
  const [changeIsMade, setChangeIsMade] = useState<boolean>(false);

  useEffect(() => {
    setUpdatedRows(planningData.rows);
  }, [planningData.rows]);

  const [openNotSavedModal, setOpenNotSavedModal] = useState<boolean>(false);

  useEffect(() => {
    const handleBeforeLeavePage = async (e: any) => {
      e.preventDefault();
      e.returnValue = '';
      shouldTriggerAction.current = true;
    };
    if (props.inEditMode) {
      window.addEventListener('beforeunload', handleBeforeLeavePage);
      document.addEventListener('visibilitychange', handleVisibilityChange);
    } else {
      window.removeEventListener('beforeunload', handleBeforeLeavePage);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    }
    return () => {
      window.removeEventListener('beforeunload', handleBeforeLeavePage);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [props.inEditMode, props.selectedPlanning]);

  const handleVisibilityChange = async () => {
    if (document.visibilityState === 'hidden' && shouldTriggerAction.current) {
      // The user confirmed leaving the page
      if (props.selectedPlanning) {
        await props.leaveEditMode(props.selectedPlanning);
      }
      shouldTriggerAction.current = false; // Reset the flag
    }
  };

  const savePlanning = async (planningId: string) => {
    if (props.currentUser && props.selectedPlanning) {
      props.setIsloading(true);
      const payload = convertToDatabase(updatedRows, planningId);
      const response = await postFromAPI(
        `/budget-planning/entries/bulk-upload/${planningId}`,
        {
          entries: payload,
        }
      );
      if (response.ok) {
        const response2 = await putFromAPI(
          `/budget-planning/update_last_edited_by/${props.selectedPlanning.id}/${props.currentUser.employee}`,
          {}
        );
        if (response2.ok) {
          setChangeIsMade(false);
        }
      }
      props.setIsloading(false);
    }
  };

  const closeModal = () => {
    setOpenNotSavedModal(false);
  };

  return (
    <StyledDiv>
      <ColumnDiv>
        <BudgetTable
          projectData={props.projectData}
          phaseData={phaseData}
          updatedRows={updatedRows}
        />
        <Graph
          projectData={props.projectData}
          weekData={weekData}
          updatedRows={updatedRows}
        />
      </ColumnDiv>
      <SaveModal
        open={openNotSavedModal}
        closeModal={closeModal}
        selectedPlanning={props.selectedPlanning}
        leaveEditMode={props.leaveEditMode}
        setChangeIsMade={setChangeIsMade}
        savePlanning={savePlanning}
      />
      {/*<NotSavedModal*/}
      {/*  openNotSavedModal={openNotSavedModal}*/}
      {/*  setOpenNotSavedModal={setOpenNotSavedModal}*/}
      {/*  selectedPlanning={props.selectedPlanning}*/}
      {/*  leaveEditMode={props.leaveEditMode}*/}
      {/*  setChangeIsMade={setChangeIsMade}*/}
      {/*/>*/}
      <PlanningTableDiv>
        <PlanningTable
          projectData={props.projectData}
          planningData={planningData}
          setUpdatedRows={setUpdatedRows}
          phaseData={phaseData}
          selectedPlanning={props.selectedPlanning}
          setSelectedPlanning={props.setSelectedPlanning}
          inEditMode={props.inEditMode}
          setIsloading={props.setIsloading}
          allPlannings={props.allPlannings}
          setAllPlannings={props.setAllPlannings}
          alreadyInUse={props.alreadyInUse}
          setChangeIsMade={setChangeIsMade}
        />
        {props.selectedPlanning && (
          <EditModeButtons
            updatedRows={updatedRows}
            inEditMode={props.inEditMode}
            alreadyInUse={props.alreadyInUse}
            changeIsMade={changeIsMade}
            setChangeIsMade={setChangeIsMade}
            selectedPlanning={props.selectedPlanning}
            setIsloading={props.setIsloading}
            enterEditMode={props.enterEditMode}
            leaveEditMode={props.leaveEditMode}
            setOpenNotSavedModal={setOpenNotSavedModal}
            currentUser={props.currentUser}
            savePlanning={savePlanning}
          />
        )}
      </PlanningTableDiv>
    </StyledDiv>
  );
}

const PlanningTableDiv = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  width: calc(100% - 40px);
  box-shadow: inset 0 0 0 1px var(--twd_web_grey);
`;
