import React from 'react';
import { styled } from 'styled-components';
import { AutoCompleteInputProps } from './AutoCompleteInput.types';

export function AutoCompleteInput(props: AutoCompleteInputProps) {
  const PlaceHolder = props.NoPlaceholderAnimation
    ? StyledPlaceHolderNoAnimation
    : StyledPlaceHolderWithAnimation;
  const handleDivClick = () => {
    if (props.inputRef.current && !props.disabled) {
      (props.inputRef.current as any).focus();
      props.setOpenModal(true);
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
      event.preventDefault();
    }
  };

  return (
    <AutoCompleteInputDiv
      isInputFieldSelected={props.openModal}
      onFocus={(event: any) => event.target.select()}
      onClick={handleDivClick}
      disabled={props.disabled}
      as={props.inputFieldStyle}
    >
      <StyledAutoCompleteInput
        value={props.inputValue}
        isInputFieldSelected={props.openModal}
        onChange={props.changeInputOnTyping}
        disabled={props.disabled}
        ref={props.inputRef}
        onKeyDown={handleKeyDown}
      />
      <PlaceHolder
        isInputFieldSelected={props.openModal}
        inputValueNotEmpty={props.inputValue !== ''}
        color={props.placeholderColor}
      >
        {props.placeholder ? props.placeholder : ''}
      </PlaceHolder>
      <InputBoxArrowDiv>
        <InputBoxArrow
          isInputFieldSelected={props.openModal}
          disabled={props.disabled}
          as={props.arrowStyle}
        />
      </InputBoxArrowDiv>
    </AutoCompleteInputDiv>
  );
}

const AutoCompleteInputDiv = styled.div<{
  isInputFieldSelected?: boolean;
  disabled?: boolean;
}>`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  border-radius: 8px;
  background: ${(props) => (props.isInputFieldSelected ? 'white' : 'inherit')};
  box-shadow: ${(props) =>
    props.isInputFieldSelected
      ? 'inset 0 0 0 1.5px var(--twd_aqua)'
      : 'inset 0 0 0 1.5px var(--twd_web_grey)'};
  color: ${(props) =>
    props.disabled
      ? 'var(--twd_web_grey)'
      : props.isInputFieldSelected
        ? 'black'
        : 'inherit'};
  cursor: ${(props) => (props.disabled ? 'initial' : 'text')};
  z-index: 1;
`;

export const StyledAutoCompleteInput = styled.input<{
  isInputFieldSelected?: boolean;
}>`
  position: relative;
  width: calc(100% - 46px);
  height: calc(100% - 4px);
  margin: 2px;
  border: 0;
  outline: none;
  font: inherit;
  background: inherit;
  border-radius: 8px;
  padding: 0 0 0 10px;
  color: inherit;
  z-index: 1;
`;

const StyledPlaceHolder = styled.span<{
  isInputFieldSelected?: boolean;
  inputValueNotEmpty?: boolean;
  color?: string;
}>`
  font: inherit;
  position: absolute;
  pointer-events: none;
  background: inherit;
  left: 0;
  top: 0;
  width: calc(90% - 20px);
  height: calc(100% - 20px);
  margin: 10px;
  z-index: 2;
  display: flex;
  align-items: center;
  color: ${(props) => (props.color ? props.color : 'var(--twd_web_grey)')};
`;

const StyledPlaceHolderNoAnimation = styled(StyledPlaceHolder)`
  visibility: ${(props) => (props.inputValueNotEmpty ? 'hidden' : 'visible')};
  color: ${(props) =>
    props.isInputFieldSelected
      ? 'var(--twd_web_grey)'
      : StyledPlaceHolder.defaultProps?.color};
`;

const StyledPlaceHolderWithAnimation = styled(StyledPlaceHolder)`
  animation: ${(props) =>
    (props.isInputFieldSelected || props.inputValueNotEmpty) &&
    'movePlaceholder 0.2s forwards'};
  color: ${(props) =>
    props.isInputFieldSelected
      ? 'var(--twd_aqua)'
      : StyledPlaceHolder.defaultProps?.color};
  background: inherit;

  @keyframes movePlaceholder {
    to {
      left: 7px;
      top: -7px;
      font-size: 10px;
      width: fit-content;
      height: fit-content;
      margin: 0;
      padding-left: 3px;
      padding-right: 3px;
    }
  }
`;

const InputBoxArrowDiv = styled.div`
  position: absolute;
  pointer-events: none;
  background: inherit;
  right: 0;
  top: 0;
  width: 12px;
  height: calc(100% - 20px);
  margin: 10px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InputBoxArrow = styled.div<{
  isInputFieldSelected: boolean;
  disabled?: boolean;
}>`
  content: ' ';
  border-left: ${(props) =>
    props.disabled ? '6px solid var(--twd_grey)' : '6px solid black'};
  border-bottom: 6px solid transparent;
  border-top: 6px solid transparent;
  animation: ${(props) =>
    props.isInputFieldSelected && 'rotateArrowDown 0s forwards'};
  z-index: 2;
  @keyframes rotateArrowDown {
    to {
      border-bottom: none;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid black;
    }
  }
`;
