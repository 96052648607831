import { InputFields } from './LeftColumn/Inputs';
import { OutputFields } from './RightColumn/Outputs';
import React, { useEffect, useState } from 'react';
import { ReportData, ReportPageProps } from './ReportType';
import { WeekNumberType } from './LeftColumn/InputType';
import { addDays, isBefore, startOfWeek } from 'date-fns';
import { changeDateToWeek } from '../Planning/Functions/TimeFunctions';
import { ActualCostingEntry, ProjectType } from '../../App.types.';
import { styled } from 'styled-components';

function getAllProjectWeeks(projectData: ProjectType) {
  let beginDate = new Date(projectData.startDate);
  beginDate = startOfWeek(beginDate, { weekStartsOn: 1 });
  let lastDate = new Date();
  lastDate = startOfWeek(lastDate, { weekStartsOn: 1 });
  const newWeekList: WeekNumberType[] = [];
  while (isBefore(beginDate, lastDate)) {
    const yearWeek = changeDateToWeek(beginDate);
    const isSpentInThisWeek = projectData.actualCosting.some(
      (actualCosting: ActualCostingEntry) => actualCosting.YearWeek === yearWeek
    );
    newWeekList.push({ Disabled: !isSpentInThisWeek, YearWeek: yearWeek });
    const weekStart = startOfWeek(beginDate, { weekStartsOn: 1 });
    beginDate = addDays(weekStart, 7);
  }
  return newWeekList.reverse();
}

export function ReportPage(props: ReportPageProps) {
  const [weeks, setWeeks] = useState<WeekNumberType[]>([]);
  const [reportData, setReportData] = useState<ReportData>({
    week: null,
    planning: null,
    phases: [],
  });
  const [hasInputsChanged, setHasInputsChanged] = useState<boolean>(false);

  useEffect(() => {
    if (props.projectData) {
      const newWeekList = getAllProjectWeeks(props.projectData);
      setWeeks(newWeekList);
    }
  }, [props.projectData]);

  const changeInputs = (updates: Partial<ReportData>) => {
    setReportData((prev) => ({ ...prev, ...updates }));
    setHasInputsChanged(true);
  };

  return (
    <ReportPageDiv>
      <InputFields
        projectData={props.projectData}
        weeks={weeks}
        plannings={props.plannings}
        reportData={reportData}
        changeInputs={changeInputs}
      ></InputFields>
      <OutputFields
        projectData={props.projectData}
        weeks={weeks}
        reportInputs={reportData}
        hasInputsChanged={hasInputsChanged}
        setHasInputsChanged={setHasInputsChanged}
      />
    </ReportPageDiv>
  );
}

const ReportPageDiv = styled.div`
  display: flex;
  width: 100%;
  height: calc(100% - 92px);
  @media (max-width: 1250px) {
    flex-direction: column;
    overflow: auto;
    height: unset;
  }
`;
