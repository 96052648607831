import React, { useEffect, useRef, useState } from 'react';
import { AutoCompleteModalProps } from './AutoCompleteModal.types';
import { AutoCompleteButton } from '../AutoCompleteButton/AutoCompleteButton';
import { AutoCompleteOption } from '../AutoComplete.types';
import { styled } from 'styled-components';

const modalRowHeight = 50;

export function AutoCompleteModal<T extends object>(
  props: AutoCompleteModalProps<T>
) {
  const elementRef = useRef(null);
  const [modalHeight, setModalHeight] = useState<string>('0px');
  const [extraSectionHeight, setExtraSectionHeight] = useState<string>('0px');

  useEffect(() => {
    let completeHeight =
      props.filteredList.length > 0
        ? props.filteredList.length * modalRowHeight
        : modalRowHeight;
    if (elementRef.current) {
      const elementHeight = (elementRef.current as any).offsetHeight;
      setExtraSectionHeight(`${elementHeight}px`);
      completeHeight += elementHeight;
    }
    setModalHeight(`${completeHeight}px`);
  }, [props]);

  const StyledModal = props.modalStyle || StyledAutoCompleteModal;

  return (
    <StyledModal modalHeight={modalHeight} extraSection={extraSectionHeight}>
      <StyledInnerDiv extraSection={extraSectionHeight}>
        {props.filteredList.length > 0 ? (
          props.filteredList.map((option: AutoCompleteOption<T>) => {
            return (
              <AutoCompleteButton
                option={option}
                isOptionSelected={
                  props.selectedOption
                    ? option.id === props.selectedOption.id
                    : false
                }
                changeSelectedOption={props.changeSelectedOption}
                buttonRefs={props.buttonRefs}
                extraButtonContent={props.extraButtonContent}
                buttonStyle={props.buttonStyle}
                hoverOverOption={props.hoverOverOption}
                disableOption={props.disableOption}
              />
            );
          })
        ) : (
          <div
            style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          >
            No options
          </div>
        )}
      </StyledInnerDiv>
      <div ref={elementRef}>
        {props.extraModalSection && <props.extraModalSection {...props} />}
      </div>
    </StyledModal>
  );
}

export const StyledAutoCompleteModal = styled.div<{
  modalHeight: string;
  extraSection: string;
}>`
  width: calc(100% - 20px);
  height: ${(props) => props.modalHeight};
  max-height: ${(props) =>
    props.extraSection ? `calc(303px + ${props.extraSection})` : '303px'};
  border: 0;
  border-radius: 0 0 8px 8px;
  box-shadow:
    inset 2px -2px 0 0 rgb(221, 221, 221),
    inset -2px 0 0 0 rgb(221, 221, 221);
  background: white;
  padding: 10px;
  position: relative;
  z-index: 3;
`;

export const StyledInnerDiv = styled.div<{ extraSection: string }>`
  width: 100%;
  height: ${(props) =>
    props.extraSection ? `calc(99% - ${props.extraSection})` : '99%'};
  min-height: 40px;
  overflow: auto;
  position: relative;
  z-index: 3;
`;
