import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SendIcon from '@mui/icons-material/Send';
import CachedIcon from '@mui/icons-material/Cached';
import { Tooltip } from '@mui/material';
import { postFromAPI } from '../../../services/apiCalls';
import { ButtonProps } from './OutputType';
import { getUserProps } from 'twd-react-components';
import { styled as styledMui } from '@mui/system';
import { styled } from 'styled-components';
import { useState } from 'react';

const FixedDiv = styled.div`
  position: absolute;
  bottom: 5%;
  right: 5%;
  display: flex;
  gap: 10px;
`;

const StyledButton = styledMui(Button)<any>(({ changed, startButton }) => ({
  backgroundColor: changed ? 'var(--twd_aqua)' : 'var(--twd_navy)',
  '@keyframes changeAnimation': {
    '0%': {
      opacity: 1,
    },
    '50%': {
      opacity: 0.5,
    },
    '100%': {
      opacity: 1,
    },
  },
  animation: changed
    ? 'changeAnimation 1s cubic-bezier(.24,0,.38,1) infinite'
    : '',
  borderRadius: startButton ? '100px' : '40px',
  width: startButton ? '180px' : '70px',
  height: startButton ? '180px' : '70px',
  boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
  fontSize: '80px',
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: 'var(--twd_aqua)',
  },
  '&.Mui-disabled': {
    background: 'lightgrey',
  },
}));

export function Buttons(props: ButtonProps) {
  const [loadingUpload, setLoadingUpload] = useState<boolean>(false);
  const [loadingMail, setLoadingMail] = useState<boolean>(false);

  // AccountProperties Context from the SignIn.tsx
  const { accessToken } = getUserProps();

  const uploadReport = () => {
    setLoadingUpload(true);
    postFromAPI('/budget-report/save', {
      project_number: props.projectData.projectNumber,
      week_number: props.reportInputs.week?.YearWeek,
      pdf: props.pdfFile,
    }).then(async (response) => {
      setLoadingUpload(false);
    });
  };

  const createMail = () => {
    setLoadingMail(true);
    postFromAPI('/budget-report/mail', {
      project_number: props.projectData.projectNumber,
      pdf64: props.pdfFile,
      access_token: accessToken,
      week_number: props.reportInputs.week?.YearWeek,
    }).then(async (response) => {
      setLoadingMail(false);
    });
  };

  return (
    <FixedDiv>
      {props.pdfFile || props.loading ? (
        <>
          <Tooltip title={'Reload report'} placement={'top'} arrow>
            <StyledButton
              changed={props.hasInputsChanged}
              component={CachedIcon}
              disabled={!props.hasInputsChanged}
              onClick={() => props.updateReport()}
            ></StyledButton>
          </Tooltip>
          <Tooltip
            title={'Not available for this demo version'}
            placement={'top'}
            arrow
          >
            <span>
              <StyledButton
                component={CloudUploadIcon}
                onClick={() => uploadReport()}
                // disabled={props.changeInputs || !props.pdfFile || props.loading || loadingUpload}
                disabled
              ></StyledButton>
            </span>
          </Tooltip>
          <Tooltip
            title={'Not available for this demo version'}
            placement={'top'}
            arrow
          >
            <span>
              <StyledButton
                component={SendIcon}
                // disabled={props.changeInputs || !props.pdfFile || props.loading || loadingMail}
                disabled
                onClick={() => createMail()}
              ></StyledButton>
            </span>
          </Tooltip>
        </>
      ) : (
        <Tooltip title={'Generate budget report'} placement={'top'} arrow>
          <StyledButton
            changed={props.hasInputsChanged}
            startButton={true}
            disabled={!props.hasInputsChanged}
            onClick={() => props.updateReport()}
          >
            GO
          </StyledButton>
        </Tooltip>
      )}
    </FixedDiv>
  );
}
