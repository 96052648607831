// Styling for the CardButton of the phases,
// that adds a border based on if the phase is selected or not.
import { styled as styledMui } from '@mui/material/styles';
import { Divider } from '@mui/material';
import { ReactComponent as IconTick } from '../../../assets/Icon_Tick.svg';
import { DetailedPhasesProps, PhasesProps } from './InputType';
import { styled } from 'styled-components';
import { Button } from 'twd-react-components';

const StyledDivider = styledMui(Divider)({
  width: '60%',
  marginLeft: '20%',
  marginRight: '20%',
  color: 'lightgrey',
  borderBottomWidth: 2,
  marginBottom: '20px',
});

const StyledDiv = styled.div`
  width: 100%;
  // 2 times 50px of the buttons with 2 times 20px margin + 20px margin of the divider = 160px
  height: calc(100% - 160px);
`;

const ScrollDiv = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: 10px;
  overflow: auto;
`;

const PhaseButton = styled(Button)<{ selected: boolean }>`
  height: 40px;
  width: calc(100% - 18px);
  min-width: 175px;
  background: inherit;
  border: 0;
  text-align: center;
  padding: 9px;
  outline: none;
  color: ${(props) => (props.disabled ? 'lightgray' : 'black')};
  border-radius: 8px;
  font: inherit;
  box-shadow: ${(props) =>
    props.selected
      ? 'inset 0 0 0 1.5px var(--twd_aqua)'
      : 'inset 0 0 0 1.5px var(--twd_web_grey)'};
  cursor: ${(props) => (props.disabled ? 'initial' : 'pointer')};

  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    transform: none;
    box-shadow: ${(props) =>
      props.disabled
        ? 'inset 0 0 0 1.5px var(--twd_web_grey)'
        : 'inset 0 0 0 1.5px var(--twd_aqua)'};
  }
`;

export function PhasesInput(props: PhasesProps) {
  return (
    <PhaseButton
      disabled={props.isDisabled}
      selected={props.hasDetailedPhases}
      onClick={() => props.setHasDetailedPhases(!props.hasDetailedPhases)}
    >
      Details Per Phase
      {!props.hasDetailedPhases ? '' : <IconTick width={'24px'} />}
    </PhaseButton>
  );
}

export function DetailedPhasesInput(props: DetailedPhasesProps) {
  return (
    <StyledDiv>
      <StyledDivider variant="middle" />
      <ScrollDiv>
        {Object.values(props.projectData.phases)
          .sort()
          .map((phase: string) => (
            <PhaseButton
              selected={props.selectedPhases.includes(phase)}
              onClick={() => props.updatePhaseList(phase)}
            >
              {phase}
              {props.selectedPhases.includes(phase) && (
                <IconTick width={'24px'} />
              )}
            </PhaseButton>
          ))}
      </ScrollDiv>
    </StyledDiv>
  );
}
