import { PDFViewer } from './pdfRender';
import { Buttons } from './Buttons';
import React, { useState } from 'react';
import { postFromAPI } from '../../../services/apiCalls';
import { OutputFieldsProps } from './OutputType';
import { styled } from 'styled-components';

const CardDiv = styled.div`
  height: 100%;
  width: calc(50% - 5px);
  margin-left: 5px;
  overflow: auto;
  display: flex;
  justify-content: center;
`;

export function OutputFields(props: OutputFieldsProps) {
  const [loading, setLoading] = useState(false);
  const [pdfFile, setPdfFile] = useState<{} | null>(null);

  console.log(props.weeks.slice(1));

  const updateReport = () => {
    setLoading(true);
    props.setHasInputsChanged(false);
    postFromAPI('/budget-report/create', {
      project_number: props.projectData.projectNumber,
      week_number: props.reportInputs.week?.YearWeek,
      phases: props.reportInputs.phases,
      planning:
        !props.reportInputs.planning || props.reportInputs.planning.id === '-1'
          ? null
          : props.reportInputs.planning,
      rates: props.projectData.rates,
      all_phases: props.projectData.phases,
      weeks: props.weeks.slice(1), // Remove the first entry so that it does not take last week into the planned calculation.
    })
      .then(async (response) => {
        const responseJson = await response.json();
        if (response.ok) {
          setPdfFile(responseJson.data);
        } else {
          setPdfFile(null);
        }
      })
      .catch((error: any) => {
        console.error('Error updating events:', error);
        setPdfFile(null);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <CardDiv>
        <PDFViewer pdfFile={pdfFile} loading={loading} />
      </CardDiv>
      <Buttons
        projectData={props.projectData}
        reportInputs={props.reportInputs}
        pdfFile={pdfFile}
        loading={loading}
        hasInputsChanged={props.hasInputsChanged}
        updateReport={updateReport}
      />
    </>
  );
}
