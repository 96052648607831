import { postFromAPI } from '../../../services/apiCalls';
import { EditToolbarProps } from './ToolBarType';
import { getPlanningEntries } from '../Functions/DatabaseFunctions';
import { PlanningSelect } from './PlanningSelect/PlanningSelect';
import { WeekSelect } from './WeekSelect/WeekSelect';
import { ToggleButtons } from '../../ToggleButtons/ToggleButton';
import { InputEntry } from '../PlanningType';
import { styled } from 'styled-components';
import { useState } from 'react';

const CustomToolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export function EditToolbar(props: EditToolbarProps) {
  const [selectedWeek, setSelectedWeek] = useState<Date>(new Date());
  const [weekViewChosen, setWeekViewChosen] = useState<boolean>(true);

  const togglePlanningView = (weeklyView: boolean) => {
    props.changePlanningSettings(selectedWeek, weeklyView);
    setWeekViewChosen(weeklyView);
  };

  const saveCopiedPlanning = async (
    oldPlanningId: string,
    newPlanningId: string
  ) => {
    const planningEntries = await getPlanningEntries(oldPlanningId);
    const payload = planningEntries.map((planningEntry: InputEntry) => {
      return { ...planningEntry, planning_id: newPlanningId };
    });
    const response = await postFromAPI(
      `/budget-planning/entries/bulk-upload/${newPlanningId}`,
      {
        entries: payload,
      }
    );
    if (response.ok) {
      console.log('helemaal goed G');
    } else {
      console.log('Ohh narr');
    }
    props.setIsloading(false);
  };
  return (
    <CustomToolbar>
      <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
        <PlanningSelect
          planningId={props.planningId}
          allPlannings={props.allPlannings}
          setAllPlannings={props.setAllPlannings}
          setPlanningId={props.setPlanningId}
          setIsLoading={props.setIsloading}
          projectId={props.projectData.projectId}
          saveCopiedPlanning={saveCopiedPlanning}
          inEditMode={props.inEditMode}
        />
        <WeekSelect
          projectData={props.projectData}
          weekView={weekViewChosen}
          setSelectedWeek={setSelectedWeek}
          changePlanningSettings={props.changePlanningSettings}
        />
        {props.planningId && props.planningId.last_edited_by && (
          <div>
            <p style={{ color: 'green' }}>
              Last edited by: <b>{props.planningId.last_edited_by}</b>
            </p>
          </div>
        )}
        {props.planningId &&
          props.planningId.last_edited_by &&
          props.alreadyInUse !== '' && <p style={{ color: 'green' }}>|</p>}
        {props.alreadyInUse !== '' && (
          <div>
            <p style={{ color: 'green' }}>
              Currently edited by: <b>{props.alreadyInUse}</b>
            </p>
          </div>
        )}
      </div>
      <ToggleButtons
        firstButtonText={'Week'}
        secondButtonText={'Day'}
        value={weekViewChosen}
        onChange={togglePlanningView}
      />
    </CustomToolbar>
  );
}
